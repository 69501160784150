<template>
    <v-container>
        <!-- <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/> -->
        <ASuccessFour :api="this.api"/>

        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar color="secondary white--text text-h5">
                        Create a new manual
                    </v-toolbar>
                    <!-- <ASuccessThree class="my-3" :api="this.api"/> -->
                    <v-text-field class="mt-5"
                    dense outlined
                    v-model="form.name"
                    label="Manual name">

                    </v-text-field>
                    <v-text-field v-model="form.description" dense counter="100"
                    outlined label="Description">
                    </v-text-field>
                    <div class="d-flex justify-end">
                        <v-btn color="primary" @click="redirectBack" plain>
                            Cancel
                        </v-btn>
                        <v-btn color="primary" @click="submit" :loading="api.isLoading" >
                            Submit
                        </v-btn>
                    </div>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
// import AConfirmation from '../../components/common/AConfirmation.vue';
// import ASuccessThree from '../../components/common/ASuccessThree.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    // AConfirmation,
    // ASuccessThree,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        form:{
            name:null,
            description:null,
        },
        isPending:false,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="storeManual"){
                this.api.success = "New Manual create succesfully";
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.api.isSuccesful = true;
        }

    },
    mounted() {
    //
    },
    methods: {
        fetch(){

        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let createManualApi = this.create();
            this.$api.fetch(createManualApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/manual";
            let formData = new FormData;
            formData.append('name',this.form.name);
            formData.append('description',this.form.description);
            formData.append('email',this.$store.getters.getEmail);
            tempApi.params = formData;
            return tempApi;
            
        },
        redirectBack(){
            this.$router.go(-1);
        }
    }
}
</script>